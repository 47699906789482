import clsx from "clsx";
import Target from "components/Target";
import { motion } from "framer-motion";
import {
  defaultChildVariants,
  defaultContainerVariants,
  defaultImageVariants,
  defaultRootVariants,
} from "lib/constants";
import { widont } from "lib/helpers";
import { observer } from "mobx-react";
import Image from "next/legacy/image";
import React, { useEffect, useState } from "react";
import { useWindowSize } from "react-use";

const HeroImage = ({
  image = null,
  imageMobile = null,
  heading = null,
  text = null,
  target = null,
  direction = 1,
}) => {
  const { width } = useWindowSize();

  const [targetImage, setTargetImage] = useState(image);

  const [isLoaded, setIsLoaded] = useState(false);

  const formattedHeading = widont(heading);
  const formattedText = widont(text);

  const showOverlay = !!heading || !!text;

  useEffect(() => {
    setTargetImage(width < 1024 && imageMobile ? imageMobile : image);
  }, [image, imageMobile, width]);

  /*
		Handlers
	*/

  return (
    <motion.div
      className={clsx(
        "relative w-full h-full opacity-0 transition duration-300 ease-out",
        {
          "opacity-100": isLoaded,
        }
      )}
      variants={defaultRootVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      {!!targetImage && !!targetImage.url && (
        <motion.div
          className={clsx(
            "flex items-center justify-center w-full h-full relative"
          )}
          variants={defaultImageVariants}
        >
          <Image
            src={targetImage.url}
            alt={image.title}
            className={clsx("user-select-none")}
            layout="fill"
            objectFit="cover"
            priority
            draggable="false"
            onLoadingComplete={() => setIsLoaded(true)}
          />
        </motion.div>
      )}
      {showOverlay && (
        <motion.div
          className={clsx(
            "absolute inset-x-0 bottom-0 text-white p-6 lg:p-16 bg-gradient-to-b from-transparent to-black/20"
          )}
          variants={defaultContainerVariants}
        >
          <Target className={clsx("space-y-2 lg:space-y-3")} target={target}>
            {!!heading && (
              <motion.h1
                className={clsx(
                  "text-balance text-3xl md:text-4xl lg:text-5xl lg:text-6xl max-w-3xl"
                )}
                custom={{ direction, distance: 75 }}
                variants={defaultChildVariants}
              >
                {formattedHeading}
              </motion.h1>
            )}
            {!!text && (
              <motion.div
                className={clsx(
                  "text-xl md:text-2xl max-w-5xl lg:text-3xl 2xl:text-3xl text-balance font-light"
                )}
                custom={{ direction, distance: 75 }}
                variants={defaultChildVariants}
              >
                {formattedText}
              </motion.div>
            )}
          </Target>
        </motion.div>
      )}
    </motion.div>
  );
};

export default observer(HeroImage);
